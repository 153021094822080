import { useState } from 'react';
import trash from '../images/trash.png';

export default function TrashBin(props) {
    const [hover, setHover] = useState(false)
    const component = document.querySelector('.trash-bin');
    const trashSentAnimation = [
        {transform: 'scale(1)'},
        {transform: 'scale(1.15)'},
        {transform: 'scale(1)'},
    ]
    const trashAnimationTiming = {
        duration: 150,
        iterations: 1,
    }
    
    function getStyle() {
        if (hover) {
            return {
                transition: '.2s ease-in-out',
                scale: '1.2',
                opacity: '1'
            }
        } else
        return {}
    }
    function handleDragOver(event) {
        event.preventDefault();
        setHover(true)
    };
    function handleDrop() {
        props.toggleDrop();
        component.animate(trashSentAnimation, trashAnimationTiming);
        setTimeout(() => {setHover(false)}, 150);
    }

    return(
      <img src={trash} className='trash-bin' onDragOver={handleDragOver} onDrop={handleDrop} onDragExit={() => setHover(false)} onMouseLeave={() => setHover(false)} style={getStyle()}/>
    )
}