import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import AllLists from './components/AllLists';
import React, { useState } from 'react';


function App() {
  const [listStorage, setListStorage] = useState(JSON.parse(localStorage.getItem('userLists')) || [{name: 'Your First Taskler Project', lists:{'To-do': ['Write your tasks here!'], 'Doing': ['Trying out Taskler'], 'Done':[]}}]);
  const [currentProject, setCurrentProject] = useState(0);
  const [titleUpdated, setTitleUpdated] = useState(false);

  function handleTitleUpdate() {
    if (titleUpdated) {setTitleUpdated(false)}
    else {setTitleUpdated(true)}
  }
  
  function switchToNewProject(i) {
    setListStorage(JSON.parse(localStorage.getItem('userLists')))
    setCurrentProject(i)
  }

  return (
      <div className="App" style={{backgroundColor:'#EFEDE4', height:'100vh'}}>
        <Header listStorage={listStorage} setCurrentProject={setCurrentProject} titleUpdated={titleUpdated} switchToNewProject={switchToNewProject}/>
        <AllLists listStorage={listStorage} projectIndex={currentProject} setTitleUpdated={setTitleUpdated} switchToNewProject={switchToNewProject}/>
        <p className='explanation-text'>Write down your tasks, click them to edit.<br></br>Drag them to a different list to manage them or to the trash bin to delete them!</p>
      </div>
  );
}

export default App;