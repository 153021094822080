import { useState } from "react"
import { Card } from "react-bootstrap"

function ListEntry(props) {
    const handleDrag = props.handleDrag
    const [hover, setHover] = useState(false);
    const [taskInput, setTaskInput] = useState(false);
    const [newTaskInput, setNewTaskInput] = useState('');

    function handleInputEnter(e) {
        if(e.key === "Enter") {
            e.preventDefault();
            const newList = [...props.list];
            newList[props.i] = newTaskInput;
            props.setList(newList);
            setTaskInput(false)
        }
        else if (e.key === 'Escape') {
            const element = document.querySelector('.edit-input');
            element.blur()
        };
    };

    function handleInput(e) {
        setNewTaskInput(e.target.value);
    };

    function compStyle() {
        if (!hover) {
            return ({
                fontSize:'.9rem', 
                padding: '5px 0',
                backgroundColor:'rgba(255, 255, 255, 0.08)', 
                borderRadius:'10px', 
                userSelect:'none', 
                animation: 'list-entry-in .3s ease-in-out',
                cursor:'pointer'
            })
        } else {
            return ({
                fontSize:'.9rem', 
                padding: '5px 0',
                backgroundColor:'rgba(255, 255, 255, 0.2)', 
                borderRadius:'10px', 
                userSelect:'none', 
                animation: 'list-entry-in .5s ease-in-out',
                cursor:'pointer'
            })
        }
    }

    return(
        <>
                {!taskInput && 
                <Card.Text key={props.i} className='list-entry' style={compStyle()} onClick={() => setTaskInput(true)} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} draggable onDragStart={(e) => handleDrag(e, props.element, props.i)}>
                {props.element}
                </Card.Text>}
                {taskInput && <input className="edit-input" autoFocus type='text' placeholder={props.element} onBlur={() => {setTaskInput(false)}} onChange={(e) => {handleInput(e)}} onKeyDown={(e) => {handleInputEnter(e)}} style={{margin: '-5px 5% 15px 5%'}}></input>}
        </>
    )
}
export default ListEntry