import { useContext, useEffect, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import ListEntry from './ListEntry';
import NewTaskOpener from './NewTaskOpener';

function ListCardNew(props) {
    const listName = props.title
    const listStorage = props.listStorage[props.projectIndex];
    const projectName = props.listStorage[props.projectIndex].name
    const [list, setList] = useState(listStorage.lists[listName]);
    const [taskInput, setTaskInput] = useState(false);
    const [newTaskInput, setNewTaskInput] = useState('');
    const setDraggedItem = props.setDraggedItem;
    const draggedItem = props.draggedItem;

    const hideEntries = [
        {transform: 'translateX(0%)',
        opacity: '1'},
        {transform: 'translateX(-10%)',
        opacity: '0'}
    ]

    useEffect(() => {
        if (list.length != 0) {
        const newStorage = props.listStorage;
        newStorage[props.projectIndex].lists[listName] = list;
        localStorage.setItem('userLists', JSON.stringify(newStorage))
        } else {
            console.log('lol')
        }
    }, [list])

    useEffect(() => {
        if (draggedItem.listname === props.title) {
            const newList = list.filter((item, index) => index !== draggedItem.index)
            setList(newList);
        }

    }, [props.dropped])

    useEffect(()=> {
        setList([]);
        setTimeout(()=>{
            setList(props.listStorage[props.projectIndex].lists[listName])
        }, 10)
    }, [props.projectIndex])

    function handleDrag(event, element, i) {
        event.dataTransfer.setData("listItem", element);
        setDraggedItem({index: i, listname: props.title})
    };
    function handleDragOver(event) {
        event.preventDefault();
    };
    function handleDragDrop(event) {
        const draggedItem = event.dataTransfer.getData("listItem");
        setList([...list, draggedItem]);
        props.toggleDrop()
    };
    function handleInputEnter(e) {
        if(e.key === "Enter") {
            setList([...list, newTaskInput])
            setTaskInput(false)
        }
        else if (e.key === 'Escape') {
            const element = document.querySelector('.newtask-input');
            element.blur()
        };
    };
    function handleInput(e) {
        setNewTaskInput(e.target.value);
    };

    return (
        <Col onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDragDrop(e)} style={{padding:'0 5%'}}>
            <Card className='list-card' style={{minWidth: '10rem', minHeight:'30%', backgroundColor:'#575658', padding:'1rem', userSelect:'none', boxShadow:'6px 12px 5px rgb(0, 0, 0, 0.25)', marginBottom: '4rem', fontFamily:'Sarabun', transition:'1s'}} text='white'>
                <Card.Title style={{marginBottom: '2rem', textAlign:'left', fontSize:'1.2rem', fontWeight:'600', userSelect:'none'}}>{props.title}</Card.Title>
                    {list.map((element, i)=> (
                        <ListEntry element={element} i={i} handleDrag={handleDrag} list={list} setList={setList}/> 
                    ))}
                <div style={{padding: '1rem'}}>
                {!taskInput && <NewTaskOpener setTaskInput={setTaskInput}/>}
                {taskInput && <input autoFocus className='newtask-input' type='text' placeholder='New Task' onBlur={() => {setTaskInput(false)}} onChange={(e) => {handleInput(e)}} onKeyDown={(e) => {handleInputEnter(e)}} style={{margin: '0 5%'}}></input>}
                </div>
            </Card>
        </Col>
    )
}

export default ListCardNew