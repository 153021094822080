import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import React, { useEffect, useState, useContext } from 'react';
import TrashBin from './TrashBin';
import ListCardNew from './ListCardNew';

export default function AllLists(props) {
  const listStorage = props.listStorage;
  const projectIndex = props.projectIndex;
  const projectTitle = listStorage[projectIndex].name
  const [draggedItem, setDraggedItem] = useState({});
  const [dropped, setDropped] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [newTitleInput, setNewTitleInput] = useState(projectTitle)

  function handleInput(e) {
        setNewTitleInput(e.target.value);
    };

  function handleInputEnter(e) {
        if(e.key === "Enter") {
            setEditingTitle(false);
            const newStorage = listStorage;
            newStorage[projectIndex].name = newTitleInput;
            localStorage.setItem('userLists', JSON.stringify(newStorage));
            props.setTitleUpdated()
        }
        else if (e.key === 'Escape') {
            const element = document.querySelector('.new-title-input');
            element.blur()
        };
  };

  function toggleDrop() {
    if (dropped) {
      setDropped(false)
    } else {
      setDropped(true)
    }
  }

  function removeProject() {
    const newStorage = listStorage;
    newStorage.splice(projectIndex, 1);
    localStorage.setItem('userLists', JSON.stringify(newStorage));
    props.switchToNewProject(0)
  }

    return(
      <Container>
        <div style={{marginTop: '2rem', display:'flex', justifyContent:'space-between'}}>
          {!editingTitle && <h4 onClick={()=>setEditingTitle(true)} style={{textAlign:'left', fontFamily:'Sarabun', cursor:'pointer', width:'fit-content'}}>{listStorage[projectIndex].name}</h4>}
          {editingTitle && <input autoFocus placeholder={newTitleInput} onChange={(e)=>handleInput(e)} onKeyDown={(e)=> handleInputEnter(e)} onBlur={()=>setEditingTitle(false)} className='new-title-input' type='text'></input>}
          {props.listStorage.length != 1 && <span onClick={removeProject} style={{fontSize:'.9rem', fontFamily:'Sarabun', cursor:'pointer'}}>Remove project</span>}
        </div>
        <Row style={{marginTop: '2rem'}}>
            <ListCardNew listStorage={listStorage} projectIndex={props.projectIndex} title={'To-do'} draggedItem={draggedItem} setDraggedItem={setDraggedItem} toggleDrop={toggleDrop} dropped={dropped}/>
            <ListCardNew listStorage={listStorage} projectIndex={props.projectIndex} title={'Doing'} draggedItem={draggedItem} setDraggedItem={setDraggedItem} toggleDrop={toggleDrop} dropped={dropped}/>
            <ListCardNew listStorage={listStorage} projectIndex={props.projectIndex} title={'Done'} draggedItem={draggedItem} setDraggedItem={setDraggedItem} toggleDrop={toggleDrop} dropped={dropped}/>
        </Row>
        <TrashBin toggleDrop={toggleDrop}/>
      </Container>
    )
}