import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


function Header(props) {
    const projectInfo = props.listStorage;

    useEffect(()=>{},[props.titleUpdated])

    function newProject() {
        let newStorage = props.listStorage;
        newStorage = [...newStorage, {name: 'New Project', lists:{'To-do':[], 'Doing':[], 'Done':[]}}];
        localStorage.setItem('userLists', JSON.stringify(newStorage));
        props.switchToNewProject(projectInfo.length);
    }

    return(
        <Navbar style={{backgroundColor:'#A2B2A1', fontFamily:'Sarabun'}}>
            <Container>
                <Navbar.Brand style={{fontSize: '2rem', color:'#302725', marginBottom:'5px'}} className="navbar-brand" href="/">Taskler</Navbar.Brand>
                <NavDropdown title="Your Projects" id="basic-nav-dropdown">
                    {projectInfo.map((element, i) => (
                        <NavDropdown.Item onClick={()=>props.setCurrentProject(i)} style={{textAlign:'center'}}>{element.name}</NavDropdown.Item>
                    ))
                    }
                    <NavDropdown.Item style={{textAlign:'center'}} onClick={newProject}>+</NavDropdown.Item>
                </NavDropdown>
            </Container>
        </Navbar>
    )
}

export default Header