import { useState } from "react"

function NewTaskOpener(props) {
    const [hover, setHover] = useState(false)

    function handleHover() {
        if (!hover) {
            setHover(true)
        } else {
            setHover(false)
        }
    }

    function compStyle() {
        if (!hover) {
            return ({
            height:'2rem', 
            backgroundColor:'rgba(255, 255, 255, 0.08)', 
            borderRadius: '5px',
            margin: '0 5%',
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '.8rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
            })
        } else {
            return ({
            height:'2rem', 
            backgroundColor:'rgba(255, 255, 255, 0.3)', 
            borderRadius: '5px',
            margin: '0 5%',
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '.8rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
            })
        }
    }

    return(
        <div className='new-task-opener' onClick={()=>{props.setTaskInput(true)}} onMouseOver={handleHover} onMouseOut={handleHover} style={compStyle()}>
            Add new task
        </div>
    )
}

export default NewTaskOpener